import * as Yup from "yup";

export const paintValidation = Yup.object().shape({
    walls: Yup.array()
        .of(
            Yup.object().shape({
                width: Yup.string().test(
                    "areaWall",
                    "A área da parede não pode ter menos do que 1m² e nem mais que 50m², por favor ajuste a largura ou altura da parede",
                    (value, context) => {
                    let areaWall = context.parent.height * context.parent.width;
                    return areaWall < 1 || areaWall > 50 ? false : true;
                }).required("O campo Largura da Parede é obrigatório"),
                height: Yup.string().test(
                    "heightWall",
                    "A altura da parede deve ser 30 centímetros maior que a altura da porta, por favor aumente a altura da parede",
                    (value, context) => {
                    return context.parent.ports > 0 && context.parent.height < 1.90 + 0.30 ? false : true;
                }).required("O campo Altura da Parede é obrigatório"),
                ports: Yup.string().required("O campo Quantidade de Portas é obrigatório"),
                windows: Yup.string().test(
                    "sizeAreaPortsWindowsMaxWall",
                    "O total da área das portas e janelas deve ser no máximo 50% da área da parede",
                    (value, context) => {
                    let areaWall = context.parent.height * context.parent.width;
                    let areaTotalPortsWindows = (context.parent.ports * 0.80 * 1.90) + (context.parent.windows * 2.00 * 1.20);
                    return areaWall * 0.50 < areaTotalPortsWindows ? false : true;
                }).required("O campo Quantidade de Janelas é obrigatório"),
            })
        )
});
