import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaintRequestType, PaintStateType } from "../../types/paintType";
import { axiosErrorHandler } from "../../utils/errors";
import RoomService from "../../services/roomService";

export const calculateAmountPaints = createAsyncThunk("room/calculateAmountPaints", async (payload: PaintRequestType, { rejectWithValue }) => {
    try {
        const response = await RoomService.calculateAmountPaints(payload);
        return response.data;
    } catch (err) {
        const error = axiosErrorHandler(err)
        return rejectWithValue(error);
    }
});

const initialState: PaintStateType = {
    paints: null,
    errors: null,
    isLoading: false,
};

export const roomSlice = createSlice({
    name: "room",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(calculateAmountPaints.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(calculateAmountPaints.fulfilled, (state, action) => {
            state.paints = action.payload;
            state.isLoading = false;
            state.errors = null;
        });
        builder.addCase(calculateAmountPaints.rejected, (state, action) => {
            state.errors = action.payload;
        });
    },
});

export default roomSlice.reducer;
