import { api } from '../config/api';
import { AxiosResponse } from "axios";
import { PaintRequestType, PaintResponseType } from '../types/paintType';

const calculateAmountPaints = (payload: PaintRequestType): Promise<AxiosResponse<PaintResponseType>> => {
    return api.post<PaintResponseType>(`room/calculate-paints`, payload);
}

const RoomService = {
    calculateAmountPaints,
};

export default RoomService;
