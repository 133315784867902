import { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => (
    <header >
        <div className="container">
            <h1 className="header-logo">
                <Link to="/">
                    DIGITAL REPUBLIC
                </Link>
            </h1>
        </div>
    </header>
);

export default Header;
