import { FC, useState, useEffect } from "react";
import { ErrorMessage, Formik, Field  } from "formik";
import { useAppDispatch, useAppSelector, RootState } from "../../stores/store";
import Card from "../../components/cards/Card";
import { paintValidation } from "../../validations/paintValidation";
import { calculateAmountPaints } from "../../stores/features/roomSlice";

const CalculatePaintsFormRoom: FC = () => {
    const dispatch = useAppDispatch();

    const paints = useAppSelector((state: RootState) => state.room.paints);

    const [result, setResult] = useState<any>(null);

    useEffect(() => {
        if(paints?.data) {
            setResult(paints.data);
        }
    }, [paints, dispatch]);

    return (
        <Formik
            validationSchema={paintValidation}
            onSubmit={async (payload) => {
                await dispatch(calculateAmountPaints(payload));
            }}
            initialValues={{
                walls: [
                    {
                        width: 0,
                        height: 0,
                        ports: 0,
                        windows: 0
                    },
                    {
                        width: 0,
                        height: 0,
                        ports: 0,
                        windows: 0
                    },
                    {
                        width: 0,
                        height: 0,
                        ports: 0,
                        windows: 0
                    },
                    {
                        width: 0,
                        height: 0,
                        ports: 0,
                        windows: 0
                    }
                ],
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="form">
                    <div className="row">
                        <div className="double-column">
                            <div className="row">
                                <div className="column">
                                    <Card>
                                        <h4 className="card-title">Parede 1</h4>
                                        <label>Largura da Parede (em metros)</label>
                                        <Field type="number" name="walls[0].width" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[0].width" />
                                        </div>
                                        <label>Altura da Parede (em metros)</label>
                                        <Field type="number" name="walls[0].height" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[0].height" />
                                        </div>
                                        <label>Quantidade de Portas</label>
                                        <Field type="number" name="walls[0].ports" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[0].ports" />
                                        </div>
                                        <label>Quantidade de Janelas</label>
                                        <Field type="number" name="walls[0].windows" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[0].windows" />
                                        </div>
                                    </Card>
                                </div>
                                <div className="column">
                                    <Card>
                                        <h4 className="card-title">Parede 2</h4>
                                        <label>Largura da Parede (em metros)</label>
                                        <Field type="number" name="walls[1].width" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[1].width" />
                                        </div>
                                        <label>Altura da Parede (em metros)</label>
                                        <Field type="number" name="walls[1].height" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[1].height" />
                                        </div>
                                        <label>Quantidade de Portas</label>
                                        <Field type="number" name="walls[1].ports" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[1].ports" />
                                        </div>
                                        <label>Quantidade de Janelas</label>
                                        <Field type="number" name="walls[1].windows" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[1].windows" />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "15px" }}>
                                <div className="column">
                                    <Card>
                                        <h4 className="card-title">Parede 3</h4>
                                        <label>Largura da Parede (em metros)</label>
                                        <Field type="number" name="walls[2].width" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[2].width" />
                                        </div>
                                        <label>Altura da Parede (em metros)</label>
                                        <Field type="number" name="walls[2].height" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[2].height" />
                                        </div>
                                        <label>Quantidade de Portas</label>
                                        <Field type="number" name="walls[2].ports" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[2].ports" />
                                        </div>
                                        <label>Quantidade de Janelas</label>
                                        <Field type="number" name="walls[2].windows" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[2].windows" />
                                        </div>
                                    </Card>
                                </div>
                                <div className="column">
                                    <Card>
                                        <h4 className="card-title">Parede 4</h4>
                                        <label>Largura da Parede (em metros)</label>
                                        <Field type="number" name="walls[3].width" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[3].width" />
                                        </div>
                                        <label>Altura da Parede (em metros)</label>
                                        <Field type="number" name="walls[3].height" step="0.10" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[3].height" />
                                        </div>
                                        <label>Quantidade de Portas</label>
                                        <Field type="number" name="walls[3].ports" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[3].ports" />
                                        </div>
                                        <label>Quantidade de Janelas</label>
                                        <Field type="number" name="walls[3].windows" min="0" />
                                        <div className="invalid">
                                            <ErrorMessage name="walls[3].windows" />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <Card>
                                <h4 className="card-title" style={{ textAlign: "left" }} >Informações:</h4>
                                <div className="margin-bootom-1">Largura de Porta: 0,80 </div>
                                <div>Altura de Porta: 1,90 </div>
                                <br/>
                                <div className="margin-bootom-1">Largura de Janela: 2,00 </div>
                                <div className="margin-bootom-1">Altura de Janela: 1,20 </div>
                            </Card>
                            <div className="text-end" style={{ marginBottom: "20px" }}>
                                <button type="submit" className="btn btn-success">
                                    Calcular
                                </button>
                            </div>
                            {
                                result ? <Card>
                                    <h4 className="card-title" style={{ textAlign: "left" }} ><strong>Resultado do Cálculo:</strong></h4>
                                    <div className="margin-bootom-1">Tamanho da área da sala: <strong>{ result.area_total_walls.toLocaleString("pt-BR") }</strong></div>
                                    <div className="margin-bootom-1">Quantidade de tinta (em litros): <strong>{ result.liters_paints_total.toLocaleString("pt-BR") }</strong></div>
                                    <div className="margin-bootom-1"><div>Quantidade de latas de tintas necessárias: </div><strong>
                                    {
                                        Object.keys(result.cans).map((key: any) => (
                                            <span> {result.cans[key]} lata{result.cans[key] > 1 ? 's' : ''} de {key} { Object.keys(result.cans).pop() !== key ? '+' : ''} </span>
                                        ))
                                    }
                                    </strong>
                                    </div>
                                </Card> : null
                            }
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default CalculatePaintsFormRoom;
