import { FC } from "react";
import CalculatePaintsFormRoom from "../components/rooms/CalculatePaintsFormRoom";

const HomePage: FC = () => (
    <main className="container" style={{ paddingBottom: "80px" }}>
        <h2>Calculadora de Pintura</h2>
        <h3>
            Faça o cálculo para descobrir a quantidade de tinta necessária para pintar uma sala.
            Preencha os campos das 4 paredes abaixo.
        </h3>
        <CalculatePaintsFormRoom />
    </main>
);

export default HomePage;
